import axios from "axios";
import config from "../config.js";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
  timeout: 1000 * 1200,
});

instance.interceptors.request.use(async (conf) => {
  const token = localStorage.getItem("token");
  conf.headers.Authorization = token ? "Bearer " + token : "";
  conf.headers.ContentType = "application/json";
  return conf;
});

export const getOneUser = async (id) =>
  await instance.get(`procurement/users/search/${id}`);

export const getUserEmail = async () =>
  await instance.get(`procurement/users/get-email`);

export const joinWaitlist = async (email, company, useCase) =>
  await instance.post(`procurement/users/waitlist`, {
    email: email,
    company: company,
    use_case: useCase,
  });

export const getAll = async () => await instance.get("procurement/users/all");

export const getUserDashboardStats = async (userId) =>
  await instance.post("procurement/users/get_dashboard_stats", { userId });

export const register = async (name, email, password, userType, signupType) =>
  await instance.post("procurement/users/register", {
    name,
    email,
    password,
    userType,
    signupType,
  });

export const registersso = async (token, userType, signupType) =>
  await instance.post("procurement/users/registersso", {
    token,
    userType,
    signupType,
  });

export const confirmRegister = async (id) =>
  await instance.post(`procurement/users/confirm/${id}`);

export const forgotPassword = async (email) =>
  await instance.post("procurement/users/forgotpassword", { email });

export const changePassword = async (id, password) =>
  await instance.post(`procurement/users/changepassword/${id}`, { password });

export const changePasswordEmail = async (email, newPassword) =>
  await instance.post(`procurement/users/changepasswordemail/`, {
    email,
    newPassword,
  });

export const login = async (email, password, googleToken) => {
  return await instance.post("procurement/users/login", {
    email: email,
    password: password,
    googleToken: googleToken || null,
  });
};

export const loginsso = async (token) => {
  return await instance.post("procurement/users/loginsso", {
    token,
  });
};

export const logout = async (token) =>
  await instance.post("procurement/users/logout", { token });

export const edit = async (userID, name, email, userType) =>
  await instance.post("procurement/users/edit", {
    userID,
    name,
    email,
    userType,
  });

export const checkSession = async (token) =>
  await instance.post("procurement/users/checkSession", { token });

export const addSolvedQuestion = async (userId, currentSolvedQuestion) =>
  await instance.post("procurement/users/addSolvedQuestion", {
    userId,
    currentSolvedQuestion,
  });

export const addSolvedQuestions = async (userId, localSolvedQuestions) =>
  await instance.post("procurement/users/addSolvedQuestions", {
    userId,
    localSolvedQuestions,
  });

export const getSolvedQuestions = async (userId) =>
  await instance.post("procurement/users/getSolvedQuestions", { userId });

export const addSolvedSet = async (userId, setId) =>
  await instance.post("procurement/users/addSolvedSet", { userId, setId });

export const refer = async (name, email, userId) =>
  await instance.post("refer/", {
    name,
    email,
    userId,
  });

// tasks

export const createTask = async (
  userId,
  title,
  description,
  color,
  startDate,
  endDate,
) =>
  await instance.post("tasks/add", {
    userId,
    title,
    description,
    color,
    startDate,
    endDate,
  });

export const editTask = async (
  id,
  title,
  description,
  color,
  startDate,
  endDate,
) =>
  await instance.post("tasks/edit", {
    id,
    title,
    description,
    color,
    startDate,
    endDate,
  });

export const getTasksByUser = async (userId) =>
  await instance.post("tasks/user", { userId });

export const getTaskById = async (id) => await instance.post(`tasks/one/${id}`);

export const deleteTask = async (id) =>
  await instance.post("tasks/delete", { id });

// posts

export const getPosts = async () => await instance.post("posts/all");

export const getPostsFromToday = async () =>
  await instance.post("posts/published");

export const getPostsById = async (id) =>
  await instance.post(`posts/one/${id}`);

export const addPost = async (
  title,
  content,
  categories,
  tags,
  authorName,
  publishedAt,
) =>
  await instance.post("posts/add", {
    title,
    content,
    categories,
    tags,
    authorName,
    publishedAt,
  });

export const editPost = async (
  id,
  title,
  content,
  categories,
  tags,
  authorName,
  publishedAt,
) =>
  await instance.post("/posts/edit", {
    id,
    title,
    content,
    categories,
    tags,
    authorName,
    publishedAt,
  });

export const deletePost = async (id) =>
  await instance.post("posts/delete", { id });

export const getPostByCateogory = async (category) =>
  await instance.post("posts/categories", { category });

// quiz

export const getQuizzes = async () => await instance.get(`quizzes/all/`);

export const getQuizzesByAuthor = async (userId) =>
  await instance.get(`quizzes/all/${userId}`);

export const getQuizById = async (id) =>
  await instance.post(`quizzes/search/${id}`);

// set

export const getSets = async () => await instance.post(`sets/all/`);

export const getSetsByAuthor = async (userId) =>
  await instance.post(`sets/all/${userId}`);

export const getSetById = async (id) =>
  await instance.post(`sets/search/${id}`);

// question

export const getQuestions = async () => await instance.post("questions/all");

// export const getQuestionsById = async id => (
//     await instance.post(`questions/search/${id}`)
// );

export const getQuestionById = async (id) =>
  await instance.post(`questions/search/${id}`);

export const getQuestionsByAuthor = async (authorName) =>
  await instance.post("questions/search/", { authorName });

export const getQuestionsByTags = async (tags) =>
  await instance.post("questions/search/", { tags });

export const getQuestionsByCategories = async (categories) =>
  await instance.post("questions/search/", { categories });

export const addQuestion = async (
  title,
  description,
  questiontype,
  options,
  answer,
  categories,
  tags,
  authorName,
  difficulty,
  paid,
) =>
  await instance.post("questions/add", {
    title,
    description,
    questiontype,
    options,
    answer,
    categories,
    tags,
    authorName,
    difficulty,
    paid,
  });

export const editQuestion = async (
  title,
  description,
  questiontype,
  options,
  answer,
  categories,
  tags,
  authorName,
  difficulty,
  paid,
) =>
  await instance.post("questions/edit", {
    title,
    description,
    questiontype,
    options,
    answer,
    categories,
    tags,
    authorName,
    difficulty,
    paid,
  });

export const deleteQuestion = async (id) =>
  await instance.post("questions/delete", { id });

// App
export const getApps = async (userId, appId) =>
  await instance.post("apps/all", { userId, appId });

export const getAppById = async (id) => await instance.get(`apps/search/${id}`);

// export const createApp = async (title, description, appType, userId, api_key) =>
//   await instance.post("apps/create", {
//     title,
//     description,
//     appType,
//     userId,
//     api_key,
//   });

// API Keys
export const getAPIKeys = async (userId, appId) =>
  await instance.post("apikeys/all", { userId, appId });

export const createAPIKey = async (userId, appId) =>
  await instance.post("apikeys/create", { userId, appId });

// API Calls
export const getAPICalls = async ({ app_id, user_id, api_key }) =>
  await instance.post("api/all", {
    app_id: app_id,
    user_id: user_id,
    api_key: api_key,
  });

export const getAPICallById = async (id) =>
  await instance.get(`api/search/${id}`);

// eval

export const doEval = async ({
  input,
  user_id,
  app_id = null,
  api_key = null,
}) =>
  await instance.post("api/evaluate_text", {
    input: input,
    user_id: user_id,
    app_id: app_id,
    api_key: api_key,
  });

export const getBasicPutData = async (appId, testId, api_key) =>
  await instance.get(`apps/${appId}/tests/${testId}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getBasicPutDataDetails = async (
  appId,
  testId,
  api_key,
  put_type,
  put_subtype,
) =>
  await instance.get(
    `apps/${appId}/tests/${testId}/details?put_type=${put_type}&put_subtype=${put_subtype}`,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getAppsByUser = async (api_key) =>
  await instance.get(`apps/all`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getAppsSimpleByUser = async (api_key) =>
  await instance.get(`apps/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const createApp = async (
  title,
  description,
  appType,
  model,
  endpoint,
  customHeaders,
  api_key,
) =>
  await instance.post(
    `/apps/create`,
    {
      title: title,
      description: description,
      appType: appType,
      model: model,
      endpoint: endpoint,
      headers: customHeaders,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const logOut = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await logout(token);
    const { data } = response;
    if (data.success) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
    }
  }
};

export const createTest = async (appId, api_key, benchmark_id) =>
  await instance.post(
    `/apps/${appId}/tests/create`,
    {
      benchmark_id: benchmark_id || null,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const generateQA = async (
  api_key,
  files,
  filenames,
  autobench_type,
  benchmarks = {
    rag_benchmark: null,
    out_of_context: null,
    prompt_injection: null,
    pii_leak: null,
    // "prompt_variation",
  },
) =>
  await instance.post(
    `/benchmarks/generate`,
    {
      inputs: files,
      benchmarks: benchmarks,
      filenames: filenames,
      autobench_type: autobench_type,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const generateEval = async (api_key, file, test_id, qa) =>
  await instance.post(
    `/api/${test_id}/docqa_answer_evaluate`,
    {
      input: file,
      qa: qa,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getDashboardStats = async (api_key) =>
  await instance.get(`/apps/dashboard`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getRTQsbyApp = async (api_key, app_id) =>
  await instance.get(`/apps/${app_id}/rtqs`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getDashboardRTQs = async (api_key, days) =>
  await instance.get(`/apps/dashboard/rtqs?days=${days}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getDashboardTests = async (api_key, days) =>
  await instance.get(`/apps/dashboard/tests?days=${days}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getBenchmarks = async (api_key) =>
  await instance.get(`/benchmarks/all`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getBenchmarkData = async (api_key, benchmark_id) =>
  await instance.get(`/benchmarks/${benchmark_id}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const updateBenchmark = async (api_key, benchmark_id, name, type) =>
  await instance.put(
    `/benchmarks/update/${benchmark_id}`,
    {
      name: name,
      type: type,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const uploadBenchmark = async (api_key, data) =>
  await instance.post(`/benchmarks/upload`, data, {
    headers: {
      "api-key": api_key,
    },
  });

export const getBenchmarkArena = async (api_key, benchmarkId) =>
  await instance.get(`/benchmarks/arena/${benchmarkId}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getBenchmarkArenaCompare = async (
  api_key,
  benchmark_id,
  queryParams,
) =>
  await instance.get(
    `/benchmarks/arena/compare/${benchmark_id}?${queryParams}`,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getBenchmarkArenaTests = async (api_key, benchmarkId) =>
  await instance.get(`/benchmarks/arena/tests/${benchmarkId}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getBenchmarkArenaAll = async (api_key, benchmarkId, queryParams) =>
  await instance.get(`/benchmarks/arena/all/${benchmarkId}?${queryParams}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const promptGameEvaluate = async (system_prompt) =>
  await instance.post(`/prompt-game/evaluate`, {
    system_prompt: system_prompt,
  });

export const genericDelete = async (api_key, endpoint) =>
  await instance.delete(endpoint, {
    headers: {
      "api-key": api_key,
    },
  });

export const updateBenchmarkPut = async (
  benchmark_id,
  put_id,
  put_data,
  api_key = null,
) =>
  await instance.put(
    `/benchmarks/${benchmark_id}/${put_id}`,
    {
      query: put_data.query,
      expected_response: put_data.expected_response,
      context: put_data.context,
      filename: put_data.filename,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const deleteBenchmarkPut = async (
  benchmark_id,
  put_id,
  api_key = null,
) =>
  await instance.delete(`/benchmarks/${benchmark_id}/${put_id}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getRTQs = async (api_key) =>
  await instance.get(`/rtqs/all`, {
    headers: {
      "api-key": api_key,
    },
  });

export const rtqFeedback = async (api_key, rtq_id, feedback) =>
  await instance.patch(
    `/rtqs/feedback/${rtq_id}`,
    {
      feedback: feedback.feedback,
      label: feedback.label,
      is_approved: feedback.is_approved,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const evalFeedback = async (api_key, testId, putId, feedback) =>
  await instance.patch(
    `/apps/tests/${testId}/put/${putId}/feedback`,
    {
      feedback: feedback.feedback,
      label: feedback.label,
      is_approved: feedback.is_approved,
      rating: feedback.rating,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const chatbotWayfair = async (api_key, question, history) =>
  await instance.post(`/chatbots/wayfair`, {
    question: question,
    history: history,
  });

export const createChatbotLLM = async (
  api_key,
  model,
  chatbotType,
  temperature = 1,
  max_tokens = 2048,
  top_p = 1,
  searchType = "similarity",
  amountOfDocuments = 4,
  db = "",
  chunkSize = 3000,
  chunkOverlap = 500,
  chunkingStrategy = "recursive_text_splitter",
  prompt = null,
) =>
  await instance.post(
    `/chatbots/`,
    {
      model: model,
      temperature: temperature,
      max_tokens: max_tokens,
      top_p: top_p,
      searchType: searchType,
      k: amountOfDocuments,
      chatbotType: chatbotType,
      db: db,
      chunkSize: chunkSize,
      chunkOverlap: chunkOverlap,
      chunkingStrategy: chunkingStrategy,
      prompt: prompt,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createChatbotCollectionLLM = async (
  api_key,
  model,
  chatbotType,
  files,
  temperature = 1,
  max_tokens = 2048,
  top_p = 1,
  searchType = "similarity",
  amountOfDocuments = 4,
  chunkSize = 3000,
  chunkOverlap = 500,
  chunkingStrategy = "recursive_text_splitter",
) => {
  const formData = new FormData();
  for (let file of files) {
    formData.append("file", file);
  }
  formData.append("model", model);
  formData.append("temperature", temperature.toString());
  formData.append("max_tokens", max_tokens.toString());
  formData.append("top_p", top_p.toString());
  formData.append("searchType", searchType);
  formData.append("k", amountOfDocuments.toString());
  formData.append("chatbotType", chatbotType);
  formData.append("chunkSize", chunkSize.toString());
  formData.append("chunkOverlap", chunkOverlap.toString());
  formData.append("chunkingStrategy", chunkingStrategy);

  return await instance.post("/chatbots/collection/", formData, {
    headers: {
      "api-key": api_key,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createURLChatbotCollectionLLM = async (
  api_key,
  model,
  chatbotType,
  url,
  temperature = 1,
  max_tokens = 2048,
  top_p = 1,
  searchType = "similarity",
  amountOfDocuments = 4,
  chunkSize = 3000,
  chunkOverlap = 500,
  chunkingStrategy = "recursive_text_splitter",
) =>
  await instance.post(
    `/chatbots/url/collection/`,
    {
      model: model,
      temperature: temperature,
      max_tokens: max_tokens,
      top_p: top_p,
      searchType: searchType,
      k: amountOfDocuments,
      chatbotType: chatbotType,
      url: url,
      chunkSize: chunkSize,
      chunkOverlap: chunkOverlap,
      chunkingStrategy: chunkingStrategy,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const queryChatbot = async (api_key, query, history, chatbotId) =>
  await instance.post(
    `/chatbots/${chatbotId}/`,
    {
      query: query,
      history: history,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const queryProductURLChatbot = async (api_key, url, chatbotId) =>
  await instance.post(
    `/chatbots/url/product/${chatbotId}/`,
    { url: url },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const queryProductURLChatbotMulti = async (api_key, data, chatbotId) =>
  await instance.post(
    `/chatbots/urls/product/${chatbotId}/`,
    { data: data },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const querySQLChatbot = async (api_key, query, history, chatbotId) =>
  await instance.post(
    `/chatbots/sql/${chatbotId}`,
    {
      query: query,
      history: history,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const queryCSVChatbot = async (
  api_key,
  query,
  history,
  fileBase64,
  chatbotId,
) =>
  await instance.post(
    `/chatbots/csv/${chatbotId}`,
    {
      query: query,
      history: history,
      fileBase64: fileBase64,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const queryPDFChatbot = async (
  api_key,
  query,
  history,
  fileBase64,
  chatbotId,
) =>
  await instance.post(
    `/chatbots/pdf/${chatbotId}`,
    {
      query: query,
      history: history,
      fileBase64: fileBase64,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const queryInvoiceChatbot = async (
  api_key,
  query,
  history,
  fileBase64,
  chatbotId,
) =>
  await instance.post(
    `/procurement/invoice/${chatbotId}`,
    {
      query: query,
      history: history,
      fileBase64: fileBase64,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const queryPamChatbot = async (api_key, query, history = []) =>
  await instance.post(
    `/procurement/chatbot/`,
    {
      query: query,
      history: history,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateApiKeys = async (
  api_key,
  anyscale,
  openAIKey,
  googleAPIKey,
) =>
  await instance.patch(
    `/procurement/users/keys/update`,
    {
      anyscale: anyscale,
      openAIKey: openAIKey,
      googleAPIKey: googleAPIKey,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const sampleAgentEval = async (
  api_key,
  agent_id,
  query,
  expected_response,
  generated_response,
) => {
  return await instance.post(
    `/agents/${agent_id}`,
    {
      query: query,
      expected_response: expected_response,
      generated_response: generated_response,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );
};

export const createAgent = async (
  api_key,
  llmAgents,
  masterAgent,
  evaluationClasses,
  examples = [],
) => {
  return await instance.post(
    `/agents/`,
    {
      agents: llmAgents,
      master_agent: masterAgent,
      eval_categories: evaluationClasses,
      examples: examples,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );
};

export const listAgents = async (api_key) => {
  return await instance.get(`/agents/`, {
    headers: {
      "api-key": api_key,
    },
  });
};

export const getFileURL = async (api_key, fileId) =>
  await instance.get(`/files/${fileId}/url/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getInvoiceDetails = async (api_key, fileBase64) =>
  await instance.post(
    `/chatbots/invoice/`,
    { fileBase64: fileBase64 },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getQuoteDetails = async (api_key, fileBase64, companyID) =>
  await instance.post(
    `/procurement/quote/extract-data-odyssey`,
    { fileBase64: fileBase64, companyId: companyID },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getPODetails = async (api_key, fileBase64) =>
  await instance.post(
    `/procurement/quote/extract-po-data-odyssey`,
    { fileBase64: fileBase64 },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const compareRFQvQuote = async (api_key, fileBase64, rfqText, email) =>
  await instance.post(
    `/procurement/widgets/rfqvquote/`,
    { fileBase64: fileBase64, rfq: rfqText, email: email },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const quoteUpload = async (
  api_key,
  fileBase64,
  filename,
  rfqText,
  email,
) =>
  await instance.post(
    `/procurement/widgets/quote-upload/`,
    { fileBase64: fileBase64, filename: filename, rfq: rfqText, email: email },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const compareInvoiceVsPO = async (
  api_key,
  InvoicefileBase64,
  POfileBase64,
  emailid,
) =>
  await instance.post(
    `/procurement/widgets/invoicevpo/`,
    {
      InvoicefileBase64: InvoicefileBase64,
      POfileBase64: POfileBase64,
      email: emailid,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const invoiceUpload = async (
  api_key,
  InvoicefileBase64,
  POfileBase64,
  filename,
) =>
  await instance.post(
    `/procurement/widgets/invoice-upload/`,
    {
      InvoicefileBase64: InvoicefileBase64,
      POfileBase64: POfileBase64,
      filename: filename,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const acknowledgementUpload = async (
  api_key,
  AcknowledgementFileBase64,
  POfileBase64,
  filename,
) =>
  await instance.post(
    `/procurement/widgets/acknowledgement-upload/`,
    {
      AcknowledgementfileBase64: AcknowledgementFileBase64,
      POfileBase64: POfileBase64,
      filename: filename,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const packingSlipUpload = async (
  api_key,
  PackingSlipFileBase64,
  POfileBase64,
  filename,
) =>
  await instance.post(
    `/procurement/widgets/packing-slip-upload/`,
    {
      fileBase64: PackingSlipFileBase64,
      POfileBase64: POfileBase64,
      filename: filename,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const poUpload = async (api_key, fileBase64, filename, mimeType) =>
  await instance.post(
    `/procurement/widgets/po-upload/`,
    {
      fileBase64: fileBase64,
      filename: filename,
      mimeType: mimeType,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const addPartManual = async (api_key, partName) =>
  await instance.post(
    `/procurement/parts/manual/`,
    { partName: partName },
    { headers: { "api-key": api_key } },
  );

export const addOrdersManual = async (
  api_key,
  partNames,
  fileBase64,
  filename,
  mimeType,
  response,
  details = {},
) =>
  await instance.post(
    `/procurement/orders/manual/`,
    {
      partNames: partNames,
      fileBase64: fileBase64,
      filename: filename,
      mimeType: mimeType,
      response: response,
      details: details,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const addQuoteManualSet = async (
  api_key,
  setId,
  partNames,
  fileBase64,
  filename,
  mimeType,
  response,
  details = {},
) =>
  await instance.post(
    `/procurement/order/${setId}/upload/`,
    {
      partNames: partNames,
      fileBase64: fileBase64,
      filename: filename,
      mimeType: mimeType,
      response: response,
      details: details,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createBOM = async (
  api_key,
  fileBase64,
  filename,
  emails,
  subject,
  body,
  details,
) =>
  await instance.post(
    `/procurement/bom/`,
    {
      file: fileBase64,
      filename: filename,
      emails: emails,
      subject: subject,
      body: body,
      details: details,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getSupplierAutocomplete = async (api_key, query) =>
  await instance.get(`/procurement/supplier/autocomplete/${query}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const createPart = async (
  api_key,
  parts,
  emails,
  subject,
  body,
  details,
) =>
  await instance.post(
    `/procurement/parts/`,
    {
      parts: parts,
      emails: emails,
      subject: subject,
      body: body,
      details: details,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createSet = async (
  api_key,
  parts,
  emails,
  subject,
  body,
  details,
) =>
  await instance.post(
    `/procurement/orders/`,
    {
      parts: parts,
      emails: emails,
      subject: subject,
      body: body,
      details: details,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getVendorsByPart = async (api_key, part_id) =>
  await instance.get(`/procurement/parts/${part_id}/vendors/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getVendorsBySet = async (api_key, set_id) =>
  await instance.get(`/procurement/order/${set_id}/vendors/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getParts = async (api_key) =>
  await instance.get(`/procurement/parts/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getSetVendors = async (api_key, set_id) =>
  await instance.get(`/procurement/order/${set_id}/vendors/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getAllSets = async (api_key) =>
  await instance.get(`/procurement/orders/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getOrder = async (api_key, set_id) =>
  await instance.get(`/procurement/order/${set_id}/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getPart = async (api_key, part_id) =>
  await instance.get(`/procurement/parts/${part_id}/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getThreadMessages = async (api_key, thread_id) =>
  await instance.get(`/procurement/gmail/threads/${thread_id}/messages/`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getOutlookProcessedEmails = async (
  api_key,
  skip = 0,
  limit = 100,
  type = "",
) =>
  await instance.get(
    `/procurement/outlook/processed-emails/me?skip=${skip}&limit=${limit}&type=${type}`,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getOutlookProcessedEmailsUserStats = async (api_key, type) =>
  await instance.get(
    `/procurement/outlook/processed-emails/me/stats?type=${type}`,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getOutlookProcessedEmailsUser = async (api_key, object_id) =>
  await instance.get(`/procurement/outlook/processed-emails/me/${object_id}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getOutlookProcessedEmailsUserPatch = async (
  api_key,
  object_id,
  data,
  comparison,
) =>
  await instance.patch(
    `/procurement/outlook/processed-emails/me/${object_id}`,
    {
      data: data,
      comparison: comparison,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createReqonOdysseyfromQuote = async (
  api_key,
  quoteData,
  companyId = null,
) =>
  await instance.post(
    `/procurement/purchase-orders/create-req-from-quote`,
    {
      quoteData: quoteData,
      companyId: companyId,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createPOonOdysseyfromQuote = async (
  api_key,
  quoteData,
  reqNumber,
  companyId = null,
) =>
  await instance.post(
    `/procurement/purchase-orders/create-po-from-quote`,
    {
      quoteData: quoteData,
      reqNumber: reqNumber,
      companyId: companyId,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createPOInOdoo = async (api_key, poData) =>
  await instance.post(
    `/procurement/odoo/purchase-orders/create-po`,
    {
      poData: poData,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createAPInvoice = async (api_key, object_id) =>
  await instance.post(
    `/procurement/invoice/insert-ap-invoice-batch/${object_id}`,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateInvoiceComparisonLineItems = async (
  api_key,
  object_id,
  comparison_line_items,
) =>
  await instance.patch(
    `/procurement/invoice/comparison/line-items/${object_id}`,
    {
      comparison_line_items: comparison_line_items,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateAcknowledgmentComparisonLineItems = async (
  api_key,
  object_id,
  comparison_line_items,
) =>
  await instance.patch(
    `/procurement/acknowledgment/comparison/line-items/${object_id}`,
    {
      comparison_line_items: comparison_line_items,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateComparisonOtherCharges = async (
  api_key,
  object_id,
  comparison_other_charges,
) =>
  await instance.patch(
    `/procurement/invoice/comparison/other-charges/${object_id}`,
    {
      comparison_other_charges: comparison_other_charges,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateComparisonPO = async (
  api_key,
  object_id,
  comparison_line_items,
) =>
  await instance.patch(
    `/procurement/acknowledgment/update-po/${object_id}`,
    {
      comparison_line_items: comparison_line_items,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getAcknowledgment = async (api_key, ack_id) =>
  await instance.get(`/procurement/acknowledgment/${ack_id}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getLogistics = async (api_key, order_id) =>
  await instance.get(`/procurement/logistics/order/${order_id}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const getLogisticsPO = async (api_key, po_number) =>
  await instance.get(`/procurement/logistics/po/${po_number}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const importPOFromOdyssey = async (api_key, payload) =>
  await instance.post(
    `/procurement/purchase-orders/import-po-from-odyssey`,
    payload,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

// export const fetchPOsFromOdyssey = async (api_key, params) =>
//   await instance.get(`/procurement/purchase-orders/fetch-from-odyssey`, {
//     params,
//     headers: {
//       "api-key": api_key,
//     },
// });

export const fetchPOItemsFromOdyssey = async (api_key, params) =>
  await instance.get(
    `/procurement/purchase-orders/fetch-poitems-from-odyssey`,
    {
      params,
      headers: {
        "api-key": api_key,
      },
    },
  );

export const fetchSuppliersFromOdyssey = async (api_key, params) =>
  await instance.get(`/procurement/purchase-orders/suppliers-odyssey`, {
    params,
    headers: {
      "api-key": api_key,
    },
  });

export const getScheduledEmails = async (api_key, order_id, params) =>
  await instance.get(
    `/procurement/logistics/order/${order_id}/schedule-email`,
    {
      params,
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getAllScheduledEmails = async (api_key, params) =>
  await instance.get(`/procurement/logistics/schedule-email`, {
    params,
    headers: {
      "api-key": api_key,
    },
  });

export const createBulkReminders = async (api_key, payload) =>
  await instance.post(`/procurement/logistics/schedule-bulk-email`, payload, {
    headers: {
      "api-key": api_key,
    },
  });

export const createBulkOverdueEmails = async (api_key, payload) =>
  await instance.post(
    `/procurement/logistics/schedule-bulk-overdue-email`,
    payload,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createScheduledReminderEmail = async (
  api_key,
  order_id,
  payload,
) =>
  await instance.post(
    `/procurement/logistics/order/${order_id}/schedule-email`,
    payload,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateScheduledReminderEmail = async (
  api_key,
  order_id,
  email_oid,
  payload,
) =>
  await instance.patch(
    `/procurement/logistics/order/${order_id}/schedule-email/${email_oid}`,
    payload,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const deleteScheduledReminderEmail = async (
  api_key,
  order_id,
  email_oid,
  payload,
) =>
  await instance.delete(
    `/procurement/logistics/order/${order_id}/schedule-email/${email_oid}`,
    payload,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateAcknowledgmentComparisonLineItemsNew = async (
  api_key,
  object_id,
  comparison_line_items,
) =>
  await instance.patch(
    `/procurement/acknowledgment/comparison-ack/line-items/${object_id}`,
    {
      comparison_line_items: comparison_line_items,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateAcknowledgmentComparisonPO = async (
  api_key,
  object_id,
  comparison_line_items,
) =>
  await instance.patch(
    `/procurement/acknowledgment/update-po-ack/${object_id}`,
    {
      comparison_line_items: comparison_line_items,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateAcknowledgmentComparisonOtherCharges = async (
  api_key,
  object_id,
  comparison_other_charges,
) =>
  await instance.patch(
    `/procurement/acknowledgment/comparison-ack/other-charges/${object_id}`,
    {
      comparison_other_charges: comparison_other_charges,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createMaterial = async (
  materialId,
  materialDescription,
  unitOfMeasurement,
  glAccount,
  taxable,
  salesTaxable,
  purchasePriceVariance,
  preferredSupplier,
  inventoryType,
  stock,
  minQuantity,
  maxQuantity,
  averageCost,
  standardCost,
  estimatedCost,
  projectCost,
  frozenCost,
) =>
  await instance.post("/procurement/materials/", {
    materialId: materialId,
    materialDescription: materialDescription,
    unitOfMeasurement: unitOfMeasurement,
    glAccount: glAccount,
    taxable: taxable,
    salesTaxable: salesTaxable,
    purchasePriceVariance: purchasePriceVariance,
    preferredSupplier: preferredSupplier,
    inventoryType: inventoryType,
    stock: stock,
    minQuantity: minQuantity,
    maxQuantity: maxQuantity,
    averageCost: averageCost,
    standardCost: standardCost,
    estimatedCost: estimatedCost,
    projectCost: projectCost,
    frozenCost: frozenCost,
  });

export const findMaterial = async (
  api_key,
  material_id_start,
  materialIdMaxCount = 10,
) =>
  await instance.get(`/procurement/materials/find-open/${material_id_start}`, {
    headers: {
      "api-key": api_key,
    },
    params: {
      maxNumber: materialIdMaxCount,
    },
  });

// RFQ

export const getNewRFQNumber = async (api_key) =>
  await instance.post(`/procurement/rfq/new`, {
    headers: {
      "api-key": api_key,
    },
  });

export const createNewRFQ = async (
  api_key,
  rfqData,
  rfqnumber,
  subject,
  body,
) =>
  await instance.post(
    `/procurement/rfq/create-with-email`,
    {
      rfqData,
      rfqnumber,
      subject,
      body,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

// REQ and PO

// -- ASHLEY
export const createReqFromSpreadsheet = async (api_key, data) =>
  await instance.post(
    `/procurement/purchase-orders/spreadsheet/req`,
    {
      data: data,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createPOFromSpreadsheet = async (api_key, data, req_number) =>
  await instance.post(
    `/procurement/purchase-orders/spreadsheet/po`,
    { data: data, req_number: req_number },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

// -- JESSICA

export const createReqFromSpreadsheetGrouped = async (api_key, data) =>
  await instance.post(
    `/procurement/purchase-orders/spreadsheet/req-grouped`,
    {
      data: data,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createPOFromSpreadsheetGrouped = async (
  api_key,
  data,
  req_number,
) =>
  await instance.post(
    `/procurement/purchase-orders/spreadsheet/po-grouped`,
    {
      data: data,
      req_number: req_number,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createReqFromSpreadsheetGroupedSherry = async (
  api_key,
  data,
  companyId,
) =>
  await instance.post(
    `/procurement/purchase-orders/spreadsheet/req-grouped-sherry`,
    {
      data: data,
      companyId: companyId,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const createPOFromSpreadsheetGroupedSherry = async (
  api_key,
  data,
  req_number,
  companyId,
) =>
  await instance.post(
    `/procurement/purchase-orders/spreadsheet/po-grouped-sherry`,
    {
      data: data,
      req_number: req_number,
      companyId: companyId,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getPO = async (api_key, po_number) =>
  await instance.get(`/procurement/purchase-orders/${po_number}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const updateReqAndPO = async (api_key, req_number, po_number, data) =>
  await instance.put(
    `/procurement/purchase-orders/update-req-po/${req_number}/${po_number}`,
    data,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getMaterial = async (api_key, material_id, companyId = null) =>
  await instance.get(`/procurement/materials/get/${material_id}`, {
    headers: {
      "api-key": api_key,
    },
    params: {
      companyId: companyId,
    },
  });

export const findMaterialByDescription = async (
  api_key,
  description,
  filter_operator,
) =>
  await instance.get(
    `/procurement/materials/find-open-by-description/${description}?filter_operator=${filter_operator}`,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

// SCORECARD

export const getOTIF = async (api_key, params) =>
  await instance.get(`/procurement/scorecards/get-otif`, {
    params,
    headers: {
      "api-key": api_key,
    },
  });

export const widgetContractRAG = async (
  api_key,
  fileId,
  fileBase64,
  filename,
  mimeType,
  query,
  history,
  email,
) =>
  await instance.post(
    `/procurement/widgets/contracts/rag/`,
    {
      file_id: fileId,
      file_base64: fileBase64,
      filename: filename,
      mime_type: mimeType,
      query: query,
      history: history,
      email: email,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const widgetBrokersRAG = async (
  api_key,
  query,
  history,
  email,
  context,
) =>
  await instance.post(
    `/procurement/widgets/brokers/rag/`,
    {
      query: query,
      history: history,
      email: email,
      context: context,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const getOTIFGLAccount = async (
  api_key,
  startDate,
  endDate,
  account,
  includePOItems = false,
) =>
  await instance.get(`/procurement/scorecards/get-otif-gl-account`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      account: account,
      include_po_items: includePOItems,
    },
    headers: {
      "api-key": api_key,
    },
  });

export const exportSpendAnalytics = async (api_key, data) =>
  await instance.post(`/procurement/scorecards/gl-account/export`, data, {
    headers: {
      "api-key": api_key,
    },
  });

export const callFactorsDemo = async (api_key, data) =>
  await instance.post(`/procurement/widgets/call/factors-demo`, data, {
    headers: {
      "api-key": api_key,
    },
  });

export const callBrokersDemo = async (api_key, data) =>
  await instance.post(`/procurement/widgets/call/brokers-demo`, data, {
    headers: {
      "api-key": api_key,
    },
  });

export const callFactorsDemoNew = async (api_key, data) =>
  await instance.post(`/procurement/widgets/call/factors-demo-new`, data, {
    headers: {
      "api-key": api_key,
    },
  });

export const callFreightDemo = async (api_key, data) =>
  await instance.post(`/procurement/widgets/call/freight-demo`, data, {
    headers: {
      "api-key": api_key,
    },
  });

export const callFactorBrokerPaymentStatusDemo = async (api_key, data) =>
  await instance.post(
    `/procurement/widgets/call/factors-broker-payment-status-demo`,
    data,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const callCarrierFactorPaymentStatusDemo = async (api_key, data) =>
  await instance.post(
    `/procurement/widgets/call/carrier-factor-payment-status-demo`,
    data,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const invoiceMatching = async (api_key, payload) =>
  await instance.post(`/procurement/widgets/invoice-matching/`, payload, {
    headers: {
      "api-key": api_key,
    },
  });

export const getBrokersDocuments = async (api_key, params) =>
  await instance.get(`/procurement/brokers/documents`, {
    params,
    headers: {
      "api-key": api_key,
    },
  });

export const getBrokersDocument = async (api_key, documentId) =>
  await instance.get(`/procurement/brokers/documents/${documentId}`, {
    headers: {
      "api-key": api_key,
    },
  });

export const createBrokersDocument = async (api_key, s3Urls) =>
  await instance.post(
    `/procurement/brokers/documents`,
    {
      s3Urls: s3Urls,
    },
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateBrokersDocumentAudit = async (api_key, documentId, audit) =>
  await instance.put(
    `/procurement/brokers/documents/${documentId}/audit`,
    audit,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );

export const updateBrokerDocumentData = async (api_key, documentId, payload) => {
  return await instance.put(
    `/procurement/brokers/documents/${documentId}`,
    payload,
    {
      headers: {
        "api-key": api_key,
      },
    },
  );
};
