// plugins styles from node_modules
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";
// core styles
import "./assets/css/global.css";

// react library for routing
import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { lazy, Suspense } from "react";

import AuthRoute from "./components/PrivateRoute/AuthRoute";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoadingPage from "./views/LoadingPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
// import DashboardRoute from "components/PrivateRoute/DashboardRoute";
import { UserDataProvider } from "./UserDataContext";
import config from "./config";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

const ExternalRedirect = lazy(
  () => import("./views/pages/components/ExternalRedirect"),
);
const Vendors = lazy(() => import("./views/Vendors/Vendors.js"));
const PartCreate = lazy(() => import("./views/Parts/PartCreate.js"));
const Parts = lazy(() => import("./views/Parts/Parts"));
const Documents = lazy(() => import("./views/Documents/Documents"));

const DocumentsInvoice = lazy(
  () => import("./views/Documents/DocumentsInvoice.tsx"),
);
const DocumentsInvoiceDemo = lazy(
  () => import("./views/Documents/DocumentsInvoiceDemo.tsx"),
);
const DocumentsDetailsARInvoice = lazy(
  () => import("./views/Documents/DocumentsDetailsARInvoice.tsx"),
);

const DocumentsDetailsAPInvoice = lazy(
  () => import("./views/Documents/DocumentsDetailsAPInvoice.tsx"),
);

const InvoiceDemo = lazy(() => import("./views/Documents/InvoiceDemo.tsx"));

const DocumentsAck = lazy(() => import("./views/Documents/DocumentsAck.tsx"));
const DocumentsDetailsAck = lazy(
  () => import("./views/Documents/DocumentsDetailsAck.tsx"),
);

const DocumentsInvoiceLogistics = lazy(
  () => import("./views/Documents/logistics/DocumentsInvoiceLogistics.tsx"),
);
const DocumentsDetails = lazy(
  () => import("./views/Documents/DocumentsDetails"),
);
const DocumentsDetailsInvoiceLogistics = lazy(
  () =>
    import("./views/Documents/logistics/DocumentsDetailsInvoiceLogistics.tsx"),
);
const ChatSetPage = lazy(() => import("./views/Orders/ChatSetPage"));
const ContractPage = lazy(() => import("./views/ContractPage"));
const ContractNegotiations = lazy(
  () => import("./views/Demo/ContractNegotiations"),
);
const Negotiations = lazy(() => import("./views/Demo/Negotiations"));
const Factors = lazy(() => import("./views/Demo/Factors"));
const FactorInvoicePaymentRemindersDashboard = lazy(
  () => import("./views/Demo/FactorInvoicePaymentRemindersDashboard"),
);

const FactorDetails = lazy(() => import("./views/Demo/FactorDetails"));
const FreightInvoices = lazy(() => import("./views/Demo/FreightInvoices"));
const FreightInvoiceDetails = lazy(
  () => import("./views/Demo/FreightInvoiceDetails"),
);
const CreateNegotiation = lazy(() => import("./views/Demo/CreateNegotiation"));
const CreateFactorFromPOD = lazy(
  () => import("./views/Demo/CreateFactorFromPOD"),
);
const FactorAnalysis = lazy(() => import("./views/Demo/FactorAnalysis"));
const FactorAnalysisParallel = lazy(
  () => import("./views/Demo/FactorAnalysisParallel"),
);
const BrokersDashboard = lazy(() => import("./views/Brokers/BrokersDashboard"));
const BrokersDocumentDetails = lazy(
  () => import("./views/Brokers/BrokersDocumentDetails"),
);
const BrokerInvoiceAudit = lazy(
  () => import("./views/Brokers/BrokerInvoiceAudit"),
);

const BrokerAnalysis = lazy(() => import("./views/Demo/BrokerAnalysis"));
const BrokerAnalysisParallel = lazy(
  () => import("./views/Demo/BrokerAnalysisParallel"),
);

const CreateFreightFromPOD = lazy(
  () => import("./views/Demo/CreateFreightFromPOD"),
);
const RunRFQ = lazy(() => import("./views/Demo/RunRFQ"));
const ContractRAG = lazy(() => import("./views/Demo/ContractRAG"));
const VendorRAG = lazy(() => import("./views/Demo/VendorRAG"));
const SiemensRAGChatbot = lazy(
  () => import("./views/Widgets/SiemensRAGChatbot"),
);
const SiemensDashboard = lazy(() => import("./views/Widgets/SiemensDashboard"));
const SiemensQuestionnaire = lazy(
  () => import("./views/Widgets/SiemensQuestionnaire"),
);
const SiemensQuestionnaireChat = lazy(
  () => import("./views/Widgets/SiemensQuestionnaireChat"),
);
const SiemensQuestionnaireFilled = lazy(
  () => import("./views/Widgets/SiemensQuestionnaireFilled"),
);
const ContractNegotiationsDetails = lazy(
  () => import("./views/Demo/ContractNegotiationsDetails"),
);

const InvoiceWorkflow = lazy(() => import("./views/Demo/InvoiceWorkflow"));
const FactorPaymentReminders = lazy(
  () => import("./views/Demo/FactorPaymentReminders"),
);
const FactorInvoicePaymentReminders = lazy(
  () => import("./views/Demo/FactorInvoicePaymentReminders"),
);

const CreateInvoiceWorkflow = lazy(
  () => import("./views/Demo/CreateInvoiceWorkflow"),
);
const CreateFactorPaymentReminders = lazy(
  () => import("./views/Demo/CreateFactorPaymentReminders"),
);

const CreateFactorInvoicePaymentReminders = lazy(
  () => import("./views/Demo/CreateFactorInvoicePaymentReminders"),
);
const BrokerARAnalytics = lazy(() => import("./views/Demo/BrokerARAnalytics"));

const NegotiationsDetails = lazy(
  () => import("./views/Demo/NegotiationsDetails"),
);
const SearchChatbotPage = lazy(() => import("./views/SearchChatbotPage"));
const SearchChatbotPageD = lazy(() => import("./views/SearchChatbotPageDemo"));
const SearchChatbotPageDS = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan"),
);
const SearchChatbotPageDS2 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan2"),
);
const SearchChatbotPageDS3 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan3"),
);
const SearchChatbotPageDS4 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan4"),
);
const SearchChatbotPageDS5 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan5"),
);
const SearchChatbotPageDS6 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan6"),
);
const AnalyticsChatbotPageDS6 = lazy(
  () => import("./views/AnalyticsChatbotPageDemo-srijan6"),
);
const SearchChatbotPageDS7 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan7"),
);

const SearchChatbotPageDS8 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan9"),
);
const SearchChatbotPageDS11 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan11"),
);

// const InvoiceChatbotPageDS8 = lazy(
//   () => import("./views/SearchChatbotPageDemo-srijan8"),
// );

const AllProductsPage = lazy(() => import("./views/Parts/Parts-subframe.tsx"));
const AllOrdersPage = lazy(
  () => import("./views/Orders/AllOrders-subframe.tsx"),
);
const AllScheduledEmailsPage = lazy(
  () => import("./views/ShippingReminders/AllScheduledEmailsPage.tsx"),
);

const ScheduleBulkReminders = lazy(
  () => import("./views/ShippingReminders/ScheduleBulkReminders.tsx"),
);

const SendBulkOverdueEmails = lazy(
  () => import("./views/ShippingReminders/SendBulkOverdueEmails.tsx"),
);

const OrdersChatbot = lazy(() => import("./views/Orders/OrdersChatbot.tsx"));
const OneProductPage = lazy(() => import("./views/Parts/OnePart-subframe.tsx"));
const OrderVendorsPage = lazy(
  () => import("./views/Orders/OrderVendorsPage.tsx"),
);

const Order = lazy(() => import("./views/Order/Order.tsx"));
const DashboardPageNew = lazy(() => import("./views/DashboardNew"));
const SpendIntelligence = lazy(() => import("./views/SpendIntelligence.tsx"));
const SpendIntelligenceSoftware = lazy(
  () => import("./views/SpendIntelligenceSoftware.tsx"),
);
const PurchasingIntelligence = lazy(
  () => import("./views/PurchasingIntelligence"),
);
const PurchasingIntelligenceSoftware = lazy(
  () => import("./views/PurchasingIntelligenceSoftware"),
);

const RfqVsQuoteChecker = lazy(
  () => import("./views/Widgets/RfqVsQuoteChecker"),
);
const InvoiceVsPOChecker = lazy(
  () => import("./views/Widgets/InvoiceVsPOChecker"),
);
const InvoiceVsPOCheckerDemo = lazy(
  () => import("./views/Widgets/InvoiceVsPOCheckerDemo"),
);

const InvoiceVsContractCheckerDemo = lazy(
  () => import("./views/Widgets/InvoiceVsContractCheckerDemo.tsx"),
);
const InvoiceContractReportDemo = lazy(
  () => import("./views/Widgets/InvoiceContractReportDemo.tsx"),
);
const InvoiceJobReportDemo = lazy(
  () => import("./views/Widgets/InvoiceJobReportDemo.tsx"),
);
const PorkCuttingInstructions = lazy(
  () => import("./views/Widgets/PorkCuttingInstructions.tsx"),
);
const PorkCuttingInstructionsFilled = lazy(
  () => import("./views/Widgets/PorkCuttingInstructionsFilled.tsx"),
);
const TnEMeatsDashboard = lazy(
  () => import("./views/Widgets/TnEMeatsDashboard"),
);
const TnEMeatsAnalytics = lazy(
  () => import("./views/Widgets/TnEMeatsAnalytics"),
);

const InvoiceThreeWayMatching = lazy(
  () => import("./views/Widgets/InvoiceThreeWayMatching.tsx"),
);
const InvoiceThreeWayMatchingReal = lazy(
  () => import("./views/Widgets/InvoiceThreeWayMatchingReal.tsx"),
);

const AcknowledgementVsPOChecker = lazy(
  () => import("./views/Widgets/AcknowledgementVsPOChecker"),
);
const PackingSlipVsPOChecker = lazy(
  () => import("./views/Widgets/PackingSlipVsPOChecker"),
);
const PackingSlipVsPOCheckerDemo = lazy(
  () => import("./views/Widgets/PackingSlipVsPOCheckerDemo"),
);
const A = lazy(() => import("./views/Widgets/a"));
const POUpload = lazy(() => import("./views/Widgets/POUploadPage"));
const QuoteToPO = lazy(() => import("./views/POs/QuoteToPO.tsx"));
// const POtoAck = lazy(() => import("./views/POs/POtoAck.tsx"));
const POTable = lazy(() => import("./views/POs/POTable.tsx"));
// const SinglePOForm = lazy(() => import("./views/POs/SinglePOForm.tsx"));
const SpreadsheetToPOAshley = lazy(
  () => import("./views/POs/Spreadsheet2PO-Ashley.tsx"),
);
const SpreadsheetToPOJessica = lazy(
  () => import("./views/POs/Spreadsheet2PO-Jessica.tsx"),
);
const Spreadsheet2POSherry = lazy(
  () => import("./views/POs/Spreadsheet2PO-Sherry.tsx"),
);
// const SpreadsheetToPOJessicaExcel = lazy(() => import("./views/POs/Spreadsheet2PO-Jessica-Excel.tsx"));
const SearchAvailableMaterialId = lazy(
  () => import("./views/SearchAvailableMaterialId.tsx"),
);
const StartRFQForm = lazy(() => import("./views/RFQ/StartRFQForm.tsx"));
const SinglePOForm = lazy(() => import("./views/POs/SinglePOForm.tsx"));
const BOManalysis = lazy(() => import("./views/bom/BOManalysis.tsx"));

const Scorecard = lazy(() => import("./views/Scorecards/Scorecard.tsx"));
const AccountSpends = lazy(
  () => import("./views/Scorecards/AccountSpends.tsx"),
);
// Odoo routes
const QuoteToPOOdoo = lazy(() => import("./views/POs/QuoteToPOOdoo.tsx"));
const Spreadsheet2POOdoo = lazy(
  () => import("./views/POs/Spreadsheet2PO-Odoo.tsx"),
);

const AuthLayout = lazy(() => import("./layouts/Auth.js"));
const TermsView = lazy(() => import("./views/Terms.js"));
const PrivacyView = lazy(() => import("./views/Privacy.js"));
const SuccessPage = lazy(() => import("./views/Success.tsx"));

const rootElement = document.getElementById("root");

createRoot(rootElement).render(
  <GoogleOAuthProvider clientId={config.GoogleSSO_CLIENT_ID}>
    <UserDataProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <AuthRoute path="/auth" component={AuthLayout} />
            <Route path="/terms" component={TermsView} />
            <Route path="/privacy" component={PrivacyView} />
            <Route path={"/success"} component={SuccessPage} />
            <PrivateRoute path={"/parts/new"} component={PartCreate} />
            <PrivateRoute path={"/orders/newchat"} component={OrdersChatbot} />
            <PrivateRoute
              path={"/parts-old/:part_id/vendors"}
              component={Vendors}
            />
            <PrivateRoute path={"/parts-old"} component={Parts} />
            <PrivateRoute
              path={"/parts/:part_id/vendors"}
              component={OneProductPage}
            />
            <PrivateRoute path={"/parts"} component={AllProductsPage} />
            <PrivateRoute
              path={"/orders/:set_id/vendors"}
              component={OrderVendorsPage}
            />
            <PrivateRoute path={"/order/:order_id"} component={Order} />
            <PrivateRoute path={"/orders"} component={AllOrdersPage} />
            <PrivateRoute
              path={"/scheduled-emails"}
              component={AllScheduledEmailsPage}
            />
            <PrivateRoute
              path={"/schedule-reminders"}
              component={ScheduleBulkReminders}
            />
            <PrivateRoute
              path={"/send-overdue-emails"}
              component={SendBulkOverdueEmails}
            />
            <PrivateRoute path={"/order-chat/:setId"} component={ChatSetPage} />
            <PrivateRoute
              path={"/documents/ar-invoice/:alertId"}
              component={DocumentsDetailsARInvoice}
            />
            <PrivateRoute
              path={"/documents/ap-invoice/:alertId"}
              component={DocumentsDetailsAPInvoice}
            />
            <PrivateRoute
              path={"/documents/invoice"}
              component={DocumentsInvoice}
            />
            <PrivateRoute
              path={"/documents/invoice-demo"}
              component={DocumentsInvoiceDemo}
            />
            <PrivateRoute
              path={"/documents/ack/:alertId"}
              component={DocumentsDetailsAck}
            />
            <PrivateRoute path={"/documents/ack"} component={DocumentsAck} />
            <PrivateRoute
              path={"/documents/invoice-logistics/:alertId"}
              component={DocumentsDetailsInvoiceLogistics}
            />
            <PrivateRoute
              path={"/documents/invoice-logistics"}
              component={DocumentsInvoiceLogistics}
            />
            <PrivateRoute
              path={"/documents/:alertId"}
              component={DocumentsDetails}
            />
            <PrivateRoute path={"/documents"} component={Documents} />
            <PrivateRoute path={"/chatbot"} component={SearchChatbotPage} />
            <PrivateRoute path={"/chatbotd"} component={SearchChatbotPageD} />
            <PrivateRoute path={"/chatbotds"} component={SearchChatbotPageDS} />
            <PrivateRoute
              path={"/chatbotds2"}
              component={SearchChatbotPageDS2}
            />
            <PrivateRoute
              path={"/chatbotds3"}
              component={SearchChatbotPageDS3}
            />
            <PrivateRoute
              path={"/chatbotds4"}
              component={SearchChatbotPageDS4}
            />
            <PrivateRoute
              path={"/chatbotds5"}
              component={SearchChatbotPageDS5}
            />
            <PrivateRoute
              path={"/chatbotds6"}
              component={SearchChatbotPageDS6}
            />
            <PrivateRoute
              path={"/analyticschatbot"}
              component={AnalyticsChatbotPageDS6}
            />
            <PrivateRoute
              path={"/chatbotds7"}
              component={SearchChatbotPageDS7}
            />
            <PrivateRoute
              path={"/chatbotds8"}
              component={SearchChatbotPageDS8}
            />
            <PrivateRoute
              path={"/chatbotds11"}
              component={SearchChatbotPageDS11}
            />
            <Route path={"/quotecheck"} component={RfqVsQuoteChecker} />
            <PrivateRoute path={"/newRFQ"} component={StartRFQForm} />
            <PrivateRoute path={"/quote2PO"} component={QuoteToPO} />
            <PrivateRoute path={"/quote2PO-odoo"} component={QuoteToPOOdoo} />
            <PrivateRoute
              path={"/spreadsheet2PO-odoo"}
              component={Spreadsheet2POOdoo}
            />
            {/*<PrivateRoute path={"/PO2Ack"} component={POtoAck} />*/}
            {/* <PrivateRoute path={"/newPO"} component={SinglePOForm} /> */}
            <PrivateRoute path={"/bom"} component={BOManalysis} />
            <PrivateRoute
              path={"/spreadsheet2PO"}
              component={SpreadsheetToPOAshley}
            />
            <PrivateRoute
              path={"/spreadsheet2PO-ashley"}
              component={SpreadsheetToPOAshley}
            />
            <PrivateRoute
              path={"/spreadsheet2PO-jessica"}
              component={SpreadsheetToPOJessica}
            />
            <PrivateRoute
              path={"/spreadsheet2PO-sherry"}
              component={Spreadsheet2POSherry}
            />
            <PrivateRoute path={"/scorecard"} component={Scorecard} />
            <PrivateRoute path={"/spend-analytics"} component={AccountSpends} />
            {/* <PrivateRoute
              path={"/spreadsheet2PO-jessica-excel"}
              component={SpreadsheetToPOJessicaExcel}
            /> */}
            <PrivateRoute
              path={"/find-open-mim"}
              component={SearchAvailableMaterialId}
            />
            <PrivateRoute path={"/POform"} component={SinglePOForm} />
            <PrivateRoute
              path={"/invoiceverify"}
              component={InvoiceVsPOChecker}
            />
            <PrivateRoute
              path={"/quote-upload"}
              component={RfqVsQuoteChecker}
            />
            <PrivateRoute
              path={"/invoice-upload"}
              component={InvoiceVsPOChecker}
            />
            <PrivateRoute
              path={"/invoice-uploadd"}
              component={InvoiceVsPOCheckerDemo}
            />
            <PrivateRoute
              path={"/invoice-contract-compare-demo"}
              component={InvoiceVsContractCheckerDemo}
            />
            <PrivateRoute
              path={"/invoice-contract-report-demo"}
              component={InvoiceContractReportDemo}
            />
            <PrivateRoute
              path={"/invoice-job-report-demo"}
              component={InvoiceJobReportDemo}
            />
            <PrivateRoute
              path={"/pork-cutting-form"}
              component={PorkCuttingInstructions}
            />
            <PrivateRoute
              path={"/pork-cutting-form-filled"}
              component={PorkCuttingInstructionsFilled}
            />
            <PrivateRoute
              path={"/temeats/analytics"}
              component={TnEMeatsAnalytics}
            />
            <PrivateRoute path={"/temeats"} component={TnEMeatsDashboard} />
            <PrivateRoute
              path={"/invoice-3way-matching-demo"}
              component={InvoiceThreeWayMatching}
            />
            <PrivateRoute
              path={"/invoice-3way-matching-real-demo"}
              component={InvoiceThreeWayMatchingReal}
            />
            <PrivateRoute path={"/invoices-demo"} component={InvoiceDemo} />
            <PrivateRoute
              path={"/acknowledgement-upload"}
              component={AcknowledgementVsPOChecker}
            />
            <PrivateRoute
              path={"/packingslip-upload"}
              component={PackingSlipVsPOChecker}
            />
            <PrivateRoute
              path={"/packingslip-uploadd"}
              component={PackingSlipVsPOCheckerDemo}
            />
            <PrivateRoute path={"/acknowledgement-uploadd"} component={A} />
            <PrivateRoute path={"/po-upload"} component={POUpload} />
            <PrivateRoute
              path={"/po/:req_number/:ponumber"}
              component={POTable}
            />
            <PrivateRoute
              path={"/spendintelligence-software"}
              component={SpendIntelligenceSoftware}
            />
            <PrivateRoute
              path={"/spendintelligence"}
              component={SpendIntelligence}
            />
            <PrivateRoute
              path={"/purchasingintelligence"}
              component={PurchasingIntelligence}
            />
            <PrivateRoute
              path={"/purchasingintelligence-software"}
              component={PurchasingIntelligenceSoftware}
            />
            <Route
              path={"/invoice-workflow-demo"}
              component={InvoiceWorkflow}
            />
            <Route
              path={"/create-invoice-workflow-demo"}
              component={CreateInvoiceWorkflow}
            />
            <Route
              path={"/factors-payment-reminders-demo"}
              component={FactorPaymentReminders}
            />
            <Route
              path={"/factors-invoice-payment-reminders-demo"}
              component={FactorInvoicePaymentReminders}
            />
            <Route
              path={"/create-factors-payment-reminders-demo"}
              component={CreateFactorPaymentReminders}
            />
            <Route
              path={"/create-factors-invoice-payment-reminders-demo"}
              component={CreateFactorInvoicePaymentReminders}
            />
            <Route
              path={"/broker-ar-analytics"}
              component={BrokerARAnalytics}
            />
            <PrivateRoute path={"/contract"} component={ContractPage} />
            <PrivateRoute
              path={"/contract-demo/:contractNumber"}
              component={ContractNegotiationsDetails}
            />
            <PrivateRoute
              path={"/negotiations-demo/:contractNumber"}
              component={NegotiationsDetails}
            />
            <PrivateRoute
              path={"/contract-demo"}
              component={ContractNegotiations}
            />
            <PrivateRoute
              path={"/negotiations-demo"}
              component={Negotiations}
            />
            <PrivateRoute
              path={"/create-negotiation-demo"}
              component={CreateNegotiation}
            />
            <PrivateRoute path={"/factors-demo"} component={Factors} />
            <PrivateRoute
              path={"/factors-invoice-payment-reminders-dashboard-demo"}
              component={FactorInvoicePaymentRemindersDashboard}
            />
            <PrivateRoute
              path={"/factors-details-demo/:carrierId"}
              component={FactorDetails}
            />
            <PrivateRoute
              path={"/freight-invoices-demo"}
              component={FreightInvoices}
            />
            <PrivateRoute
              path={"/freight-invoice-details-demo/:invoiceId"}
              component={FreightInvoiceDetails}
            />
            <PrivateRoute
              path={"/create-factor-from-pod-demo"}
              component={CreateFactorFromPOD}
            />
            <PrivateRoute
              path={"/factor-analysis-demo"}
              component={FactorAnalysis}
            />
            <PrivateRoute
              path={"/factor-analysis-demo-parallel"}
              component={FactorAnalysisParallel}
            />
            <PrivateRoute
              path={"/brokers-dashboard"}
              component={BrokersDashboard}
            />
            <PrivateRoute
              path={"/brokers-document/:documentId"}
              component={BrokersDocumentDetails}
            />
            <PrivateRoute
              path={"/brokers-invoice-audit"}
              component={BrokerInvoiceAudit}
            />
            <PrivateRoute
              path={"/broker-analysis-demo"}
              component={BrokerAnalysis}
            />
            <PrivateRoute
              path={"/broker-analysis-demo-parallel"}
              component={BrokerAnalysisParallel}
            />
            <PrivateRoute
              path={"/create-freight-from-pod-demo"}
              component={CreateFreightFromPOD}
            />
            <PrivateRoute path={"/run-rfq-demo"} component={RunRFQ} />
            <PrivateRoute path={"/contract-qna"} component={ContractRAG} />
            <PrivateRoute path={"/vendor-qna"} component={VendorRAG} />
            <Route path={"/siemens-qna"} component={SiemensRAGChatbot} />
            <Route path={"/siemens-dashboard"} component={SiemensDashboard} />
            <Route
              path={"/siemens-questionnaire"}
              component={SiemensQuestionnaire}
            />
            <Route
              path={"/siemens-questionnaire-chat"}
              component={SiemensQuestionnaireChat}
            />
            <Route
              path={"/siemens-questionnaire-filled"}
              component={SiemensQuestionnaireFilled}
            />
            <PrivateRoute path="/" component={DashboardPageNew} exact />
            <Route path="*" render={() => <ExternalRedirect url="/" />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </UserDataProvider>
  </GoogleOAuthProvider>,
);
